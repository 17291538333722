<template>
	<div class="newFans_card">
		<div v-for="item of list" :key="item.id" class="user_row">
			<div class="userData">
				<div class="avatar">
					<el-avatar :src="item.userAvatar" ></el-avatar>
					<div class="point" v-if="!item.readState"></div>
				</div>
				<div class="userInfo">
					<div class="content">
						<div class="name">{{ item.userName }}</div>
					</div>
					<div class="time">{{ item.createTime }}</div>
				</div>
			</div>
			<div class="rightBtn">
				<div :class="['button',item.meFollowHim ? 'message' : 'follow']" @click="handleBtn(item)">
					{{ item.meFollowHim ? '去私信' : '回关' }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['list'],
	data() {
		return {};
	},
	methods: {
		/**
		 * 操作按钮事件
		 * @param model 粉丝数据
		 */
		handleBtn(model){
			if (model.meFollowHim) {
				this.handleToMessage(model.userId);
			} else {
				this.handleFollow(model.userId);
			}
		},
		/**
		 * 操作去私信
		 */
		handleToMessage(userId){
			this.$login().then(async (res) => {
				this.$store.dispatch('communicateImmediately', {
					userId: userId,
				});
			});
		},
		/**
		 * 操作关注事件
		 */
		async handleFollow(focusUserId){
			const res = await this.$http.follow({focusUserId})
			if (res.code === 200) {
					let index = this.list.findIndex( v => v.userId === focusUserId )
					this.$emit("update",index);
					this.$message({
						message: '操作成功',
						type: 'success'
					});
			}
		}
	},
};
</script>
<style lang="scss">
.newFans_card {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  display: grid;
  row-gap: 20px;
  .user_row {
    display: flex;
    justify-content: space-between;
    .userData {
      display: flex;
      align-items: center;
			.avatar {
				position: relative;
				.point {
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background-color: #FF6C27;
					position: absolute;
					top: calc(50% - 4px);
					left: -15px;
				}
        .el-avatar {
          width: 48px;
          height: 48px;
          margin-right: 11px;
        }
			}
      .userInfo {
        .content {
          display: flex;
          align-items: center;
          .name {
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 2px;
            line-height: 22px;
            max-width: 180px;
            margin-right: 11px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .apply {
            font-size: 12px;
            color: #999999;
            line-height: 18px;
          }
        }
        .applyMsg {
          color: #999999;
          line-height: 18px;
          font-size: 12px;
          margin-bottom: 2px;
        }
        .time {
          color: #999999;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .rightBtn {
      .button {
        background: #f2f2f2;
        height: 24px;
        width: 64px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;
        &.message {
          background-color: #F2F2F2;
          color: #181818;
        }
        &.follow {
          background-color: $theme-color;
          color: #fff;
        }
      }
    }
  }
}
</style>
